import axios from 'axios'
const url = '/empresas'

export default {
    create(data){
        return axios.post(`${url}/`, data)
    },
    index(is_active){
        return axios.get(url+`?is_active=${is_active}`)
    },
    
    show(id) {
        return axios.get(`${url}/${id}`)
    },
    update(data) {
        if (data.localidad && data.localidad.id) {
            data.localidad = { id: data.localidad.id };
          }
        return axios.put(`${url}/${data.id}`, data)
    },
    

}