<template>
  <v-card color="transparent">
    <v-container>
      <v-card-text>
        <v-text-field
          outlined
          v-model="empresa.razonSocial"
          autofocus
          label="Razón social"
          id="razonSocial"
          v-validate="'required|max:50'"
          :error-messages="errors.collect('razonSocial')"
          data-vv-name="razonSocial"
          :counter="50"
          required
        ></v-text-field>

        <v-text-field
          outlined
          dense
          label="Nombre fantasía"
          v-model="empresa.nombreFantasia"
          v-validate="'required|max:50'"
          :error-messages="errors.collect('nombreFantasia')"
          data-vv-name="nombreFantasia"
          :counter="50"
          required
        ></v-text-field>
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="empresa.fecha_inicio_actividades"
              label="Fecha de inicio de actividades"
              dense
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="empresa.fecha_inicio_actividades"
            :max="moment().format('YYYY-MM-DD')"
            locale="es"
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
        <v-text-field
          outlined
          v-model="empresa.direccion"
          label="Dirección"
          v-validate="'required|max:25'"
          :error-messages="errors.collect('direccion')"
          data-vv-name="direccion"
          :counter="25"
          required
        ></v-text-field>

        <v-text-field
          outlined
          type="email"
          v-model="empresa.email"
          label="E-mail"
          v-validate="'required|max:30|email'"
          :error-messages="errors.collect('email')"
          data-vv-name="email"
          required
        ></v-text-field>

        <v-text-field
          outlined
          v-model="empresa.cuit"
          label="Cuit"
          v-validate="'required|numeric|min:7|max:11'"
          :error-messages="errors.collect('cuit')"
          data-vv-name="cuit"
          :counter="30"
          required
        ></v-text-field>

        <v-autocomplete
          outlined
          :items="provincias"
          append-icon
          v-model="id_provincia"
          hide-no-data
          clearable
          item-text="nombre"
          item-value="id"
          label="Provincia"
          @change="fetchLocalidades"
        ></v-autocomplete>

        <v-autocomplete
          autocomplete="off"
          v-model="empresa.localidad.id"
          :items="localidades"
          outlined
          :search-input.sync="searchInputLocalidad"
          append-icon
          no-data-text="Sin localidades"
          clearable
          item-text="nombre"
          item-value="id"
          label="Localidad"
        ></v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-row justify="end" class="mr-2">
          <v-btn
            color="green"
            :loading="isLoading"
            @click="validateForm()"
            :disabled="errors.any()"
            >{{ textButton }}</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "@/components/inputs/VTextFieldWithValidation";
import VSelectWithValidation from "@/components/inputs/VSelectWithValidation";

export default {
  name: "empresa.form",
  components: {
    ValidationObserver,
    VTextFieldWithValidation,
    VSelectWithValidation,
  },
  $_veeValidate: {
    validator: "new",
  },
  props: {
    empresa: {
      type: Object,
      required: true,
    },
    provincias: {
      type: Array,
      required: true,
    },

    isUpdate: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      localidades: [],
      id_provincia: null,
      searchInputLocalidad: "",
      condicionIva: [
        { id: 1, nombre: "RESPONSABLE INSCRIPTO" },
        { id: 2, nombre: "MONOTRIBUTO" },
      ],
      date: this.moment().format("YYYY-MM-DD"),
      menu2: false,
    };
  },
  computed: {
    textButton() {
      return this.isUpdate ? "Guardar" : "Registrar";
    },
  },

  methods: {
    async fetchLocalidades(id_prov) {
      try {
        const localidades = await this.axios.get(
          `/soporte/provincias/${id_prov}/localidades`
        );

        this.localidades = localidades.data;
      } catch (error) {}
    },
    validateForm() {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          this.$emit("process-empresa", this.empresa);
        } else {
          this.$validator.validate();
        }
      });
    },
  },
  watch: {
    id_provincia(val) {
      console.log(val);
      this.fetchLocalidades(val);
    },
  },
  created() {
    if (this.isUpdate) {
      if (this.empresa.localidad) {
        this.id_provincia = this.empresa.localidad.provincia.id;
        this.fetchLocalidades(this.id_provincia);
      } else {
        this.empresa.localidad = { id: null };
      }
    }
  },
};
</script>

<style scoped></style>
